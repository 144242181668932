import { allPatches } from "common/src/Config";
import { render as renderPatch } from "common/src/Patch.bs";
import { toUrl as apertureToUrl } from "common/src/Aperture.bs";
import * as T from "common/src/bindings/Translation2";

export const compsItems = (
  t: (k: string) => string,
  aperturePath: string,
): Array<[string, string]> => {
  const ap = aperturePath === "" ? "" : "/" + aperturePath;
  return [
    ["/team-compositions" + ap, t("Top Comps")],
    ["/perfect-synergies", t("Perfect Synergies")],
  ];
};

export const statsItems = (
  t: (k: string) => string,
  aperturePath: string,
  afm: boolean,
): Array<[string, string]> => {
  const ap = aperturePath === "" ? "" : "/" + aperturePath;
  return [
    ["/team-compositions" + ap, t("Top Comps")],
    // ["/augments" + ap, t("Augments")],
    ["/units" + ap, t("Units")],
    ["/items" + ap, t("Items")],
    ["/traits" + ap, t("Traits")],
    [afm ? "/explorer-advanced" : "/explorer", t("Explorer")],
    ["/trends" + ap, t("Trends")],
  ];
};

export const toolsItems = (
  t: (k: string) => string,
): Array<[string, string]> => [
  ["/info/set-14/tables/cypher", t("Tables")],
  ["/tier-list/units", t("Tier Lists")],
  ["/info/pbe", t("PBE")],
  [
    "/info/patch-notes/" +
      renderPatch(allPatches.filter((p) => p !== 13250 && p % 10 === 0)[0]),
    t("Patch Notes"),
  ],
  ["/perfect-synergies", t("Perfect Synergies")],
];

export const playersItemsSub = (
  t: (k: string) => string,
): Array<[string, string]> => [
  ["/leaderboards", t("Leaderboards")],
  ["/wrapped/set-13", t("Wrapped")],
];

export const playersItemsHover = (
  t: (k: string) => string,
): Array<[string, string]> => [
  ["/leaderboards", t("Ranked Leaderboards")],
  ["/leaderboards/duo", t("Double Up Leaderboards")],
  ["/leaderboards/hyperroll", t("Hyperroll Leaderboards")],
  ["/leaderboards/bookmarks", t("Bookmark Leaderboards")],
  ["/leaderboards/unit", t("Top Unit Players")],
  ["/wrapped/set-13", t("Wrapped")],
];

export const setInfoItems = (
  t: (k: string) => string,
): Array<[string, string]> => [
  ["/info/units", t("Units")],
  ["/info/traits", t("Traits")],
  ["/info/items", t("Items")],
  ["/info/augments", t("Augments")],
  ["/info/portals", t("Portals")],
  // ["/info/anomalies", t("Anomalies")],
  // ["/info/charms", t("Charms")],
  // ["/info/legends", t("Legends")],
  ["/info/cheat-sheet", t("Synergy Grid")],
];

export const setRevivalInfoItems = (
  t: (k: string) => string,
): Array<[string, string]> => [
  ["/info/set-46/units", t("Units")],
  ["/info/set-46/traits", t("Traits")],
  ["/info/set-46/items", t("Items")],
  ["/info/set-46/augments", t("Augments")],
  // ["/info/set-46/portals", t("Portals")],
  ["/info/set-46/cheat-sheet", t("Synergy Grid")],
  ["/team-builder/V", t("Team Builder")],
];

export const getRootGroups = (
  t: (k: string) => string,
  aperture: any,
  patreonActive: boolean,
) => {
  return [
    { key: "Home", label: t("Home"), href: "/", items: [] },
    // {
    //   key: "Comps",
    //   label: t("Comps"),
    //   href: "/team-compositions",
    //   items: compsItems(t, apertureToUrl(aperture)),
    // },
    {
      key: "Stats",
      label: t("Stats"),
      href: "/team-compositions",
      items: statsItems(t, apertureToUrl(aperture), patreonActive),
    },
    {
      key: "Tools",
      label: t("Tools"),
      href: "/info/set-14/tables/cypher",
      items: toolsItems(t),
    },
    {
      key: "Players",
      label: t("Players"),
      href: "/leaderboards",
      items: playersItemsHover(t),
    },
    {
      key: "Team Builder",
      label: t("Team Builder"),
      href: "/team-builder",
      items: [],
    },
    {
      key: "Set Info",
      label: t("Set # Info").replace("#", "14"),
      href: "/info/units",
      items: setInfoItems(t),
    },
    // {
    //   key: "Set 5.5 Info",
    //   label: t("Set # Info").replace("#", "5.5"),
    //   href: "/info/set-56/units",
    //   items: setRevivalInfoItems(t),
    // },
    // {
    //   key: "Set 4.5 Info",
    //   label: t("Set # Info").replace("#", "4.5"),
    //   href: "/info/set-46/units",
    //   items: setRevivalInfoItems(t),
    // },
    {
      key: "Set Update",
      label: t("Set # Update").replace("#", "14"),
      href: "/info/set-update",
      items: [],
    },
  ];
};

export const getActiveGroup = (asPath: string) => {
  const parts = asPath
    .split("?")[0]
    .split("/")
    .filter((p) => p !== "");
  if (parts[0] === "") {
    return "Home";
  } else if (
    [
      "team-compositions",
      "augments",
      "units",
      "items",
      "traits",
      "trends",
      "exalted",
      "explorer",
      "explorer-advanced",
    ].includes(parts[0])
  ) {
    return "Stats";
  } else if (
    ["tier-list", "perfect-synergies"].includes(parts[0]) ||
    (parts[0] === "info" &&
      (["pbe", "patch-notes"].includes(parts[1]) ||
        ["tables"].includes(parts[2])))
  ) {
    return "Tools";
  } else if (["leaderboards", "player", "wrapped"].includes(parts[0])) {
    return "Players";
  } else if (parts[0] === "team-builder") {
    return "Team Builder";
  } else if (parts[0] === "info" && parts[1] === "set-56") {
    return "Set 5.5 Info";
  } else if (parts[0] === "info" && parts[1] === "set-46") {
    return "Set 4.5 Info";
  } else if (parts[0] === "info" && parts[1] !== "set-update") {
    return "Set Info";
  } else if (parts[0] === "info" && parts[1] === "set-update") {
    return "Set Update";
  } else {
    return "Home";
  }
};
